.header {
    padding-top: 40px;
    padding-bottom: 35px;
    box-shadow: 0px 0px 29px 0px rgba(138, 124, 111, 0.21);
    position: relative;
    z-index: 10;

    @media(max-width:1199px) {
        padding-top: 30px;
        padding-bottom: 35px;
    }

    @media(max-width:767px) {
        padding-top: 25px;
        padding-bottom: 20px;
    }

    @media(max-width:575px) {
        padding-top: 15px;
        padding-bottom: 18px;
    }
}

.header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media(max-width:575px) {
        flex-wrap: wrap;
    }
}

.header-company {
    display: flex;
    align-items: center;
}

.header-company-logo {
    font-size: 2.6rem;
    display: flex;
    position: relative;
    width: fit-content;
    line-height: 1;
    color: #000;
    padding-right: 20px;

}

.header-company-logo:after {
    content: '';
    position: absolute;
    right: 0;
    top: -10px;
    width: 2px;
    bottom: -10px;
    background-color: #000;

    @media(max-width:767px) {
        top: -3px;
        bottom: -3px;
    }
}

.header-company-logo__icon {
    font-size: 2em;
    font-family: 'YandexSansDisplay-Bold';
    letter-spacing: -0.23em;
    background-color: #f2ab00;
    width: fit-content;
    padding-right: 0.22em;
    margin-right: .45em;
}

.header-company-info {
    font-size: 1.8em;
    padding-left: 20px;
    width: fit-content;

    @media(max-width:767px) {
        font-size: 1.6em;
    }
}

.header-contacts {
    text-align: right;
    font-size: 1.8rem;
}


.contacts-phone {
    font-size: 1.3em;
    color: #000;
    transition: .5s;
    padding-bottom: 6px;
}

a.contacts-item:hover {
    opacity: .55;
}

.contacts-item {
    font-size: 1.8rem;
}

.header-contacts {
    @media(max-width:575px) {
        width: 100%;
    }
}

.contacts-item a,
.contacts-item i {
    color: #000;
}

.contacts-item i {
    margin-right: 10px;
    font-size: 20px;
}

.contacts-item i.fa-phone-alt {
    position: relative;
    top: -3px;
}

.header-contacts i.fa-phone-alt {
    @media(max-width:767px) {
        display: none;
    }

    @media(max-width:575px) {
        display: block;
    }
}

.header-contacts .contacts-item {
    justify-content: flex-end;

    @media(max-width:575px) {
        justify-content: center;
        margin-top: 12px;
    }
}

.header-contacts-info {
    font-size: 1.2em;
    font-weight: 700;
    width: fit-content;
    padding-bottom: 2px;
    border-bottom: 2px solid rgb(173, 173, 173);
    transition: .5s;
    margin-left: auto;
    padding-top: 6px;
    color: #000;
}

.header-contacts-info:hover {
    border-bottom: 2px solid #f2ab00;
    color: #f2ab00;
}