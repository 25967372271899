@font-face {
	font-family: 'YandexSansDisplay-Bold';
	src: url(../fonts/YandexSansDisplay-Bold.ttf);
}

@font-face {
	font-family: 'YandexSansDisplay-Regular';
	src: url(../fonts/YandexSansDisplay-Regular.ttf);
}

* {
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	color: #000;
	font-family: 'YandexSansDisplay-Regular', sans-serif;
	font-size: 10px;
	line-height: normal;
	background-color: #fff;

	@media(max-width:1199px) {
		font-size: 8px;
	}
}

a,
a:hover {
	text-decoration: none;
}


ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

h1,
h2,
h3,
p {
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%
}

input,
button {
	outline: none;
}



.container {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto;

	@media(max-width: 1600px) {
		max-width: 1200px;
		padding: 0;
	}

	@media(max-width: 1239px) {
		padding: 0 20px;
	}

	@media(max-width: 991px) {
		padding: 0px 15px;
	}
}

.page-wrapper {
	height: 100%;
	position: relative;
	transition: 1s;

}

.page-wrapper.hide {
	opacity: 0;
}

.d-flex {
	display: flex;
	align-items: center;
}

.basic-text {
	font-size: 1.7em;
}

.hidden-mobile {
	display: flex;

	@media(max-width:767px) {
		display: none;
	}
}