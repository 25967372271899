@font-face {
  font-family: 'YandexSansDisplay-Bold';
  src: url(../fonts/YandexSansDisplay-Bold.ttf);
}
@font-face {
  font-family: 'YandexSansDisplay-Regular';
  src: url(../fonts/YandexSansDisplay-Regular.ttf);
}
* {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  color: #000;
  font-family: 'YandexSansDisplay-Regular', sans-serif;
  font-size: 10px;
  line-height: normal;
  background-color: #fff;
}
@media (max-width: 1199px) {
  html,
  body {
    font-size: 8px;
  }
}
a,
a:hover {
  text-decoration: none;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
h1,
h2,
h3,
p {
  padding: 0;
  margin: 0;
}
img {
  max-width: 100%;
}
input,
button {
  outline: none;
}
.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
@media (max-width: 1600px) {
  .container {
    max-width: 1200px;
    padding: 0;
  }
}
@media (max-width: 1239px) {
  .container {
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  .container {
    padding: 0px 15px;
  }
}
.page-wrapper {
  height: 100%;
  position: relative;
  transition: 1s;
}
.page-wrapper.hide {
  opacity: 0;
}
.d-flex {
  display: flex;
  align-items: center;
}
.basic-text {
  font-size: 1.7em;
}
.hidden-mobile {
  display: flex;
}
@media (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}
.header {
  padding-top: 40px;
  padding-bottom: 35px;
  box-shadow: 0px 0px 29px 0px rgba(138, 124, 111, 0.21);
  position: relative;
  z-index: 10;
}
@media (max-width: 1199px) {
  .header {
    padding-top: 30px;
    padding-bottom: 35px;
  }
}
@media (max-width: 767px) {
  .header {
    padding-top: 25px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .header {
    padding-top: 15px;
    padding-bottom: 18px;
  }
}
.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .header-wrapper {
    flex-wrap: wrap;
  }
}
.header-company {
  display: flex;
  align-items: center;
}
.header-company-logo {
  font-size: 2.6rem;
  display: flex;
  position: relative;
  width: fit-content;
  line-height: 1;
  color: #000;
  padding-right: 20px;
}
.header-company-logo:after {
  content: '';
  position: absolute;
  right: 0;
  top: -10px;
  width: 2px;
  bottom: -10px;
  background-color: #000;
}
@media (max-width: 767px) {
  .header-company-logo:after {
    top: -3px;
    bottom: -3px;
  }
}
.header-company-logo__icon {
  font-size: 2em;
  font-family: 'YandexSansDisplay-Bold';
  letter-spacing: -0.23em;
  background-color: #f2ab00;
  width: fit-content;
  padding-right: 0.22em;
  margin-right: .45em;
}
.header-company-info {
  font-size: 1.8em;
  padding-left: 20px;
  width: fit-content;
}
@media (max-width: 767px) {
  .header-company-info {
    font-size: 1.6em;
  }
}
.header-contacts {
  text-align: right;
  font-size: 1.8rem;
}
.contacts-phone {
  font-size: 1.3em;
  color: #000;
  transition: .5s;
  padding-bottom: 6px;
}
a.contacts-item:hover {
  opacity: .55;
}
.contacts-item {
  font-size: 1.8rem;
}
@media (max-width: 575px) {
  .header-contacts {
    width: 100%;
  }
}
.contacts-item a,
.contacts-item i {
  color: #000;
}
.contacts-item i {
  margin-right: 10px;
  font-size: 20px;
}
.contacts-item i.fa-phone-alt {
  position: relative;
  top: -3px;
}
@media (max-width: 767px) {
  .header-contacts i.fa-phone-alt {
    display: none;
  }
}
@media (max-width: 575px) {
  .header-contacts i.fa-phone-alt {
    display: block;
  }
}
.header-contacts .contacts-item {
  justify-content: flex-end;
}
@media (max-width: 575px) {
  .header-contacts .contacts-item {
    justify-content: center;
    margin-top: 12px;
  }
}
.header-contacts-info {
  font-size: 1.2em;
  font-weight: 700;
  width: fit-content;
  padding-bottom: 2px;
  border-bottom: 2px solid #adadad;
  transition: .5s;
  margin-left: auto;
  padding-top: 6px;
  color: #000;
}
.header-contacts-info:hover {
  border-bottom: 2px solid #f2ab00;
  color: #f2ab00;
}
.main {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  font-size: 1.3rem;
}
.main-content {
  flex-direction: row;
}
@media (max-width: 991px) {
  .main-content {
    flex-direction: column;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 767px) {
  .main-content {
    flex-direction: column;
    max-width: 100%;
  }
}
.main-content-col--text {
  padding-top: 5rem;
  padding-bottom: 4.5rem;
}
.main-content {
  align-items: stretch;
}
.main-content-col {
  width: 50%;
  position: relative;
  z-index: 10;
}
.main-content-col .subtitle {
  padding-bottom: 1.2em;
}
@media (max-width: 991px) {
  .main-content-col {
    width: 100%;
  }
}
@keyframes showBg {
  0 {
    transform: translatex(100%);
  }
  100% {
    transform: translatex(0%);
  }
}
.main:after {
  position: absolute;
  content: '';
  width: 46%;
  transform: translatex(100%);
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #ffcc00;
  animation-name: showBg;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
@media (max-width: 991px) {
  .main:after {
    display: none;
  }
}
.title-1 {
  font-size: 3em;
  line-height: 1.1;
  font-family: 'YandexSansDisplay-Bold';
  padding-bottom: .65em;
  letter-spacing: -1px;
  width: 100%;
}
@media (max-width: 1365px) {
  .title-1 {
    width: 97%;
  }
}
@media (max-width: 991px) {
  .title-1 {
    width: 70%;
  }
}
@media (max-width: 767px) {
  .title-1 {
    width: 100%;
  }
}
.subtitle {
  font-size: 2em;
}
@keyframes buttonBg {
  0% {
    left: 0;
    right: auto;
  }
  100% {
    right: 0;
    left: auto;
  }
}
.page-button {
  position: relative;
  overflow: hidden;
  border: 0px;
  border-radius: 5px;
  background-color: #ffd102;
  box-shadow: 0px 5px 0px 0px rgba(234, 160, 0, 0.004);
  font-size: 24px;
  padding-left: 4.1em;
  padding-right: 4.1em;
  text-align: center;
  height: 54px;
  line-height: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: .5s;
}
.page-button:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}
@keyframes buttonBg {
  0 {
    left: 0;
  }
  100% {
    left: 105%;
  }
}
.page-button:before {
  content: '';
  width: 30px;
  height: 200px;
  position: absolute;
  background: #eed35b;
  background: #ffffff;
  background: linear-gradient(223deg, rgba(255, 255, 255, 0.75) 6%, rgba(255, 204, 0, 0.75) 70%);
  transform: rotate(40deg);
  top: -90%;
  left: 5px;
  animation-name: buttonBg;
  animation-delay: 1s;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}
.main-content-title-group {
  padding-bottom: 20px;
  position: relative;
}
.main-content-title-group .page-button {
  margin-bottom: 12px;
}
.main-content-title-group:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 2px;
  width: 30%;
  background-color: #000;
}
.main-content-benefits-group {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.benefits-group-item {
  padding-top: 63px;
  position: relative;
  text-align: center;
  font-size: 21px;
  line-height: 1.4;
  width: 35%;
}
@media (max-width: 1365px) {
  .benefits-group-item {
    width: 42%;
  }
}
@media (max-width: 767px) {
  .benefits-group-item {
    width: 100%;
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
}
.benefits-group-item:first-child {
  margin-right: 10%;
}
@media (max-width: 767px) {
  .benefits-group-item:first-child {
    margin-right: auto;
  }
}
.benefits-group-item:after {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background-image: url(../img/1.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.main-content-col--img {
  display: flex;
  align-items: stretch;
  align-items: center;
  justify-content: space-between;
  padding-left: 1%;
}
@media (max-width: 991px) {
  .main-content-col--img {
    display: none;
  }
}
.images-wrapper {
  justify-content: space-between;
}
.main-content-images-item {
  overflow: hidden;
  width: 32%;
  max-width: 290px;
  box-shadow: 0px 10px 21px 0px rgba(159, 19, 0, 0.26);
}
@media (max-width: 991px) {
  .main-content-images-item {
    max-width: 170px;
  }
}
.main-content-images-item img {
  max-width: 400%;
  display: block;
  position: relative;
  left: -35%;
}
.main-content-images-item .image-left {
  left: -35%;
}
.main-content-images-item .image-center {
  left: -144%;
}
.main-content-images-item .image-right {
  left: -250%;
}
.images-item__left {
  transform: translateY(150%);
}
.images-item__center {
  opacity: 0;
}
.images-item__right {
  transform: translateY(-150%);
}
@keyframes showImgTop {
  0 {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}
@keyframes showImgCenter {
  0 {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes showImgBottom {
  0 {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}
.images-item__left {
  animation-name: showImgBottom;
  animation-delay: 1s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.images-item__center {
  animation-name: showImgCenter;
  animation-delay: 1s;
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
}
.images-item__right {
  animation-name: showImgTop;
  animation-delay: 1.5s;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.main-content-images-item:first-child {
  align-self: flex-start;
}
.main-content-images-item.images-item__center {
  align-self: flex-start;
}
.main-content-images-item:last-child {
  align-self: flex-start;
}
.main-mobile-img {
  display: none;
}
@media (max-width: 991px) {
  .main-mobile-img {
    display: block;
    height: 400px;
    background-image: url(../img/img1.jpg);
    background-image: url(../img/test.jpg);
    background-size: cover;
    background-position: center;
  }
}
.footer {
  position: relative;
  z-index: 10;
  padding-top: 4rem;
  padding-bottom: 4.5rem;
  background-color: #ffffff;
  box-shadow: 0px 0px 29px 0px rgba(138, 124, 111, 0.21);
}
@media (max-width: 767px) {
  .footer {
    padding-bottom: 25px;
  }
}
.footer-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .footer-wrapper {
    flex-direction: column;
  }
}
.footer-wrapper-col {
  width: 32%;
}
@media (max-width: 767px) {
  .footer-wrapper-col {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
}
.footer-wrapper-col:last-child {
  width: 34%;
}
@media (max-width: 767px) {
  .footer-wrapper-col:last-child {
    width: 100%;
  }
}
.footer-text {
  font-size: 2.4rem;
}
.footer-contacts-info {
  display: none;
}
@media (max-width: 767px) {
  .footer-contacts-info {
    display: block;
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2px;
    border-bottom: 2px solid #000;
    padding-top: 8px;
  }
}
.footer-contacts-info:hover {
  border-bottom: 2px solid #f2ab00;
  color: #f2ab00;
}
#overlay {
  position: fixed;
  z-index: 200;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  justify-content: center;
  align-items: center;
  display: none;
}
#overlay.active {
  display: flex;
}
@keyframes showModal {
  0 {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.modal-wrapper {
  opacity: 0;
  padding: 30px;
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 600px;
  border-radius: 25px;
  box-shadow: 0 0 35px rgba(255, 255, 255, 0.25);
  border: 10px solid rgba(255, 209, 1, 0.75);
  animation-name: showModal;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}
.modal-title {
  font-size: 22px;
  font-weight: 700;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 45px;
}
.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: 0px;
  outline: none;
  font-size: 24px;
  transition: .5s;
  cursor: pointer;
  color: #999;
}
.modal-close:hover {
  opacity: .5;
}
.no-scroll {
  overflow: hidden;
}
.modal-form {
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
.input-group {
  margin-bottom: 40px;
}
.form-input {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15 rgba(0, 0, 0, 0.15);
  height: 44px;
  border-radius: 5px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
}
.form-input:focus {
  border: 2px solid #ffcc00;
  box-shadow: 0 0 15 rgba(0, 0, 0, 0.45);
}
.politic-text {
  font-size: 11px;
  max-width: 220px;
}
.form-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-button--form {
  padding-left: 60px;
  padding-right: 60px;
  margin: auto;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  margin-bottom: 20px;
}
.politic-link {
  text-decoration: underline;
}
