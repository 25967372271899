.main {
    position: relative;
    background-color: #fff;
    overflow: hidden;
    font-size: 1.3rem;
}

.main-content {
    flex-direction: row;

    @media(max-width:991px) {
        flex-direction: column;
        max-width: 80%;
        margin-left: auto;
        margin-right: auto;
    }

    @media(max-width:767px) {
        flex-direction: column;
        max-width: 100%;
    }

}

.main-content-col--text {
    padding-top: 5rem;
    padding-bottom: 4.5rem;
}

.main-content {
    align-items: stretch;
}

.main-content-col {
    width: 50%;
    position: relative;
    z-index: 10;

    .subtitle {
        padding-bottom: 1.2em;
        ;
    }

    @media(max-width:991px) {
        width: 100%;
    }
}

@keyframes showBg {
    0 {
        transform: translatex(100%);
    }

    100% {
        transform: translatex(0%);
    }

}

.main:after {
    position: absolute;
    content: '';
    width: 46%;
    transform: translatex(100%);
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #ffcc00;
    animation-name: showBg;
    animation-duration: 1s;
    animation-fill-mode: forwards;

    @media(max-width:991px) {
        display: none;

    }
}

.title-1 {
    font-size: 3em;
    line-height: 1.1;
    font-family: 'YandexSansDisplay-Bold';
    padding-bottom: .65em;
    letter-spacing: -1px;
    width: 100%;

    @media(max-width:1365px) {
        width: 97%;
    }

    @media(max-width:991px) {
        width: 70%;
    }

    @media(max-width:767px) {
        width: 100%;
    }
}

.subtitle {
    font-size: 2em;
}

@keyframes buttonBg {
    0% {
        left: 0;
        right: auto;
    }

    100% {
        right: 0;
        left: auto;
    }
}

.page-button {
    position: relative;
    overflow: hidden;
    border: 0px;
    border-radius: 5px;
    background-color: rgb(255, 209, 2);
    box-shadow: 0px 5px 0px 0px rgba(234, 160, 0, 0.004);
    font-size: 24px;
    padding-left: 4.1em;
    padding-right: 4.1em;
    text-align: center;
    height: 54px;
    line-height: 1;
    box-shadow: 0 0 20px rgba(0, 0, 0, .25);
    cursor: pointer;
    transition: .5s;

    &:hover {
        box-shadow: 0 0 20px rgba(0, 0, 0, .40);
    }

}

@keyframes buttonBg {
    0 {
        left: 0;

    }

    100% {
        left: 105%;

    }
}

.page-button:before {
    content: '';
    width: 30px;
    height: 200px;
    position: absolute;
    background: #eed35b;

    background: rgb(255, 255, 255);
    background: linear-gradient(223deg, rgba(255, 255, 255, .75) 6%, rgba(255, 204, 0, .75) 70%);
    transform: rotate(40deg);
    top: -90%;
    left: 5px;
    animation-name: buttonBg;
    animation-delay: 1s;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
}

.main-content-title-group {
    padding-bottom: 20px;
    position: relative;

    .page-button {
        margin-bottom: 12px;

    }
}

.main-content-title-group:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    width: 30%;
    background-color: #000;
}

.main-content-benefits-group {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
}

.benefits-group-item {
    padding-top: 63px;
    position: relative;
    text-align: center;
    font-size: 21px;
    line-height: 1.4;
    width: 35%;

    @media(max-width: 1365px) {
        width: 42%;
    }

    @media(max-width: 767px) {
        width: 100%;
        max-width: 320px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 30px;
    }
}

.benefits-group-item:first-child {
    margin-right: 10%;

    @media(max-width: 767px) {
        margin-right: auto;
    }
}

.benefits-group-item:after {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);

    width: 50px;
    height: 50px;
    background-image: url(../img/1.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-content-col--img {
    display: flex;
    align-items: stretch;
    align-items: center;
    justify-content: space-between;
    padding-left: 1%;
    // padding-top: 4%;

    @media(max-width:991px) {
        display: none;
    }
}

.images-wrapper {
    justify-content: space-between;
}

.main-content-images-item {
    overflow: hidden;
    width: 32%;
    max-width: 290px;
    box-shadow: 0px 10px 21px 0px rgba(159, 19, 0, 0.26);

    @media(max-width:991px) {
        max-width: 170px;
    }

    img {
        max-width: 400%;
        display: block;
        position: relative;
        left: -35%;

    }

    .image-left {
        left: -35%;
    }

    .image-center {
        left: -144%;
    }

    .image-right {
        left: -250%;
    }
}

.images-item__left {
    transform: translateY(150%)
}

.images-item__center {
    opacity: 0;
}

.images-item__right {
    transform: translateY(-150%)
}

@keyframes showImgTop {
    0 {
        transform: translateY(150%)
    }

    100% {
        transform: translateY(0%)
    }
}

@keyframes showImgCenter {
    0 {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes showImgBottom {
    0 {
        transform: translateY(150%)
    }

    100% {
        transform: translateY(0%)
    }
}

//-анимация первой картинки
.images-item__left {
    animation-name: showImgBottom;
    animation-delay: 1s; //-время задержки начала анимации
    animation-duration: 2s; //-время длительности анимации
    animation-fill-mode: forwards;
}

//-анимация второй картинки
.images-item__center {
    animation-name: showImgCenter;
    animation-delay: 1s;
    animation-duration: 3.5s;
    animation-fill-mode: forwards;
}

//-анимация третьей картинки

.images-item__right {
    animation-name: showImgTop;
    animation-delay: 1.5s;
    animation-duration: 2s;
    animation-fill-mode: forwards;
}

.main-content-images-item:first-child {
    align-self: flex-start;
}

.main-content-images-item.images-item__center {
    align-self: flex-start;
    // margin-top: 60px;

    // @media(max-width:1440px) {
    //     margin-top: 14%;
    // }

    // @media(max-width:1199px) {
    //     margin-top: 16%;
    // }
}

.main-content-images-item:last-child {
    align-self: flex-start;
}

.main-mobile-img {
    display: none;

    @media(max-width:991px) {
        display: block;
        height: 400px;
        background-image: url(../img/img1.jpg);
        background-image: url(../img/test.jpg);
        background-size: cover;
        background-position: center;
    }
}