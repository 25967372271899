.footer {
    position: relative;
    z-index: 10;
    padding-top: 4rem;
    padding-bottom: 4.5rem;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 29px 0px rgba(138, 124, 111, 0.21);

    @media(max-width:767px) {
        padding-bottom: 25px;
    }
}

.footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media(max-width:767px) {
        flex-direction: column;

    }
}

.footer-wrapper-col {
    width: 32%;

    @media(max-width:767px) {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }
}

.footer-wrapper-col:last-child {
    width: 34%;

    @media(max-width:767px) {
        width: 100%;
    }
}

.footer-text {
    font-size: 2.4rem;
}

.footer-contacts-info {
    display: none;

    @media(max-width:767px) {
        display: block;
        width: fit-content;
        font-size: 16px;
        font-weight: 700;
        color: #000;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 2px;
        border-bottom: 2px solid #000;
        padding-top: 8px;
    }
}

.footer-contacts-info:hover {
    border-bottom: 2px solid #f2ab00;
    color: #f2ab00;
}

#overlay {
    position: fixed;
    z-index: 200;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, .75);
    // display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

#overlay.active {
    display: flex;
}

@keyframes showModal {
    0 {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.modal-wrapper {
    opacity: 0;
    padding: 30px;
    position: relative;
    background-color: #fff;
    width: 100%;
    max-width: 600px;
    border-radius: 25px;
    box-shadow: 0 0 35px rgba(255, 255, 255, .25);
    border: 10px solid rgba(255, 209, 1, .75);
    animation-name: showModal;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;

}

.modal-title {
    font-size: 22px;
    font-weight: 700;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 45px;

}

.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: 0px;
    outline: none;
    font-size: 24px;
    transition: .5s;
    cursor: pointer;
    color: #999;
}

.modal-close:hover {
    opacity: .5;
}

.no-scroll {
    overflow: hidden;
}

.modal-form {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;

}


.input-group {
    margin-bottom: 40px;
}

.form-input {
    width: 100%;
    border: 2px solid rgba(0, 0, 0, .2);
    box-shadow: 0 0 15 rgba(0, 0, 0, .15);
    height: 44px;
    border-radius: 5px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 16px;
}

.form-input:focus {
    border: 2px solid #ffcc00;
    box-shadow: 0 0 15 rgba(0, 0, 0, .45);
}

.politic-text {
    font-size: 11px;
    max-width: 220px;
}

.form-button {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}

.page-button--form {
    padding-left: 60px;
    padding-right: 60px;
    margin: auto;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 700;
    height: 50px;
    margin-bottom: 20px;
}

.politic-link {
    text-decoration: underline;

}